import React, { useEffect, useState } from "react"
import axios from "axios"
const BestCoworkingForm = props => {
  const [SuccessMessage, setSuccessMessage] = useState("hide")
  const [ErrorMessage, setErrorMessage] = useState("")
  const [Name, setName] = useState("")
  const [Email, setEmail] = useState("")
  const [Phone, setPhone] = useState("")
  const [organization, setOrganization] = useState("")
  const [officesize, setofficesize] = useState("")
  const [Message, setMessage] = useState("")
  const [Button, SetButton] = useState(false)
  const [NameError, setNameError] = useState("")
  const [EmailError, setEmailError] = useState("")
  const [PhoneError, setPhoneError] = useState("")
  const [organizationsError, setorganizationsError] = useState("")
  const [officesizeError, setofficesizeError] = useState("")
  const [referrerLink, setreferrerLink] = useState("")
  const [MessageError, setMessageError] = useState("")
  useEffect(() => {
    if (document.referrer && document.referrer != "") {
      // console.log("Thanks for visiting this site from " + document.referrer)
      setreferrerLink(document.referrer)
    } else {
      // console.log("Direct Visitor")
      setreferrerLink("")
    }
  })
  function validateEmail(email) {
    // Regular expression to check the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Check if the email format is valid
    if (!emailRegex.test(email)) {
      return false
    }

    return true
  }
  const handleSubmit = e => {
    e.preventDefault()
    var datefrom = new Date().getTime()
    if (Name !== "" && Email !== "" && Phone !== "") {
      setErrorMessage("")
      if (Name.length <= 2) {
        setNameError("Enter Vaild Name")
      } else {
        setNameError("")
      }
      if (!validateEmail(Email)) {
        setEmailError("Please Enter Vaild Email Address")
      } else {
        setEmailError("")
      }
      if (!Phone.match("[0-9]{10}")) {
        setPhoneError("Please Enter Vaild Mobile No")
      } else {
        setPhoneError("")
      }
      if (organization.length <= 2) {
        setorganizationsError("Please Enter Vaild Organization Name")
      } else {
        setorganizationsError("")
      }
      if (officesize === "") {
        setofficesizeError("Please Enter Vaild Office Size")
      } else {
        setofficesizeError("")
      }
      if (
        Name.length >= 2 &&
        validateEmail(Email) &&
        Phone.match("[0-9]{10}") &&
        organization.length >= 2
      ) {
        // var myHeaders = new Headers()
        // myHeaders.append("Content-Type", "application/x-www-form-urlencoded")
        // var urlencoded = new URLSearchParams()
        // urlencoded.append("name", Name)
        // urlencoded.append("source", "Google Ad")
        // urlencoded.append("date", datefrom)
        // urlencoded.append("email", Email)
        // urlencoded.append("phone", Phone)
        // urlencoded.append("spaceId", "Google Ad")
        // urlencoded.append("numberOfSeats", officesize)
        // var requestOptions = {
        //   method: "POST",
        //   headers: myHeaders,
        //   body: urlencoded,
        //   redirect: "follow",
        // }
        // fetch(
        //   "https://gofloaters.firebaseapp.com/spaces/enquiry",
        //   requestOptions
        // )
        //   .then(response => {
        //     response.text()
        //     setSuccessMessage("show")
        //     setName("")
        //     setEmail("")
        //     setPhone("")
        //     setOrganization("")
        //     SetButton(false)
        //   })
        //   .then(result => console.log(result))
        //   .catch(error => console.log("error", error))
        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }

        axios
          .post(`https://gofloaters.firebaseapp.com/spaces/enquiry`, {
            name: Name,
            email: Email,
            source: "Google Ad",
            phone: Phone,
            referrerLink: "referrerLink",
            numberOfSeats: parseInt(officesize, 10),
            spaceId: "Office",
            city: "Chennai",
            organization: organization,
            date: datefrom,
          })
          .then(res => {
            setSuccessMessage("show")
            setName("")
            setEmail("")
            setPhone("")
            setOrganization("")
            SetButton(false)
          })
      } else {
        setErrorMessage("Please fill all the mandatory Fields")
      }
    } else {
      setNameError("Enter Vaild Name")
      setEmailError("Please enter vaild email address")
      setPhoneError("Please enter vaild mobile no")
      setorganizationsError("Please enter vaild organization")
    }
  }
  // useEffect(() => {
  //   if (
  //     Name.length > 2 &&
  //     Phone.match("[0-9]") &&
  //     Phone.length >= 10 &&
  //     validateEmail(Email)
  //   ) {
  //     SetButton(false)
  //   } else {
  //     SetButton(true)
  //   }
  // })
  return (
    <div className={"BestCoworkingForm " + props.type}>
      {SuccessMessage === "hide" ? (
        <div id="signup">
          <form>
            <div className="form-group">
              <label htmlFor="Name">
                Your Name *:{" "}
                <span style={{ color: "red", fontSize: "12px" }}>
                  {NameError}
                </span>
              </label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={Name}
                onChange={e => {
                  setName(e.currentTarget.value)
                  setErrorMessage("")
                }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">
                Email Address *:{" "}
                <span style={{ color: "red", fontSize: "12px" }}>
                  {EmailError}
                </span>
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                value={Email}
                onChange={e => {
                  setEmail(e.currentTarget.value)
                  setErrorMessage("")
                }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="Phone">
                Phone *:{" "}
                <span style={{ color: "red", fontSize: "12px" }}>
                  {PhoneError}
                </span>
              </label>
              <input
                type="text"
                name="phone"
                className="form-control"
                value={Phone}
                onChange={e => {
                  var phone = e.currentTarget.value
                  if (!phone.match("[0-9]{10}")) {
                    setPhone(e.currentTarget.value)
                  } else {
                    setErrorMessage("")
                    setPhone(e.currentTarget.value)
                  }
                }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="Name">
                Your Organization *:{" "}
                <span style={{ color: "red", fontSize: "12px" }}>
                  {organizationsError}
                </span>
              </label>
              <input
                type="text"
                name="organization"
                className="form-control"
                value={organization}
                onChange={e => {
                  setOrganization(e.currentTarget.value)
                  setErrorMessage("")
                }}
                required
              />
            </div>
            {props.type == "" ? (
              <div className="form-group">
                <label htmlFor="Name">
                  Preferred Office Size *:
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {officesizeError}
                  </span>
                </label>
                <select
                  type="text"
                  name="officesize"
                  className="form-control"
                  value={officesize}
                  onChange={e => {
                    setofficesize(e.currentTarget.value)
                    setErrorMessage("")
                  }}
                  required
                >
                  <option value="">Select Office Size</option>
                  <option value="10">5-10</option>
                  <option value="25">10 - 25</option>
                  <option value="25+">25+</option>
                </select>
              </div>
            ) : (
              ""
            )}

            <button
              onClick={handleSubmit}
              disabled={Button}
              className="btn btn-default"
            >
              Request Call Back!
            </button>
          </form>

          {ErrorMessage === "" ? (
            ""
          ) : (
            <p style={{ color: "red", fontWeight: "bold" }}>
              <br />

              {ErrorMessage}
            </p>
          )}
        </div>
      ) : (
        <div>
          <p style={{ color: "red", fontWeight: "bold" }}>{ErrorMessage}</p>
          <div className={"message " + SuccessMessage}>
            Thanks for submitting your requirement. We will call you shortly.
          </div>
        </div>
      )}
    </div>
  )
}

export default BestCoworkingForm
